import React from 'react';
import feimg from '../../images/features/feature.svg'
import './style.css'



const WpoFeatures = () => {

    return(
        <div className="wpo-features-area">
            <div className="container">
                <div className="wpo-section-title text-center">
                    <span>The Future is Now</span>
                    <h2>Modern Features for Scale</h2>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="wpo-features-item-2">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="wpo-feature-wrap">
                                    <div className="wpo-features-icon2">
                                        <i className="fi flaticon-plane"></i>
                                    </div>
                                    <div className="wpo-features-text">
                                        <h3>Direct API Access</h3>
                                        <p>Seamlessly integrate our services into your applications with direct API access, enhancing efficiency and control.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="wpo-feature-wrap">
                                    <div className="feature-icon3">
                                        <i className="fi flaticon-truck"></i>
                                    </div>
                                    <div className="wpo-features-text">
                                        <h3>Real-Time Delivery Quotes</h3>
                                        <p>Access instant, accurate delivery quotes to make informed decisions quickly and keep your business moving.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 mt-5">
                        <div className="wpo-features-item mt-5">
                            <div className="wpo-feature-img">
                                <img src={feimg} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="wpo-features-item">
                            <div className="col-lg-12 col-md-6 col-sm-12">
                                <div className="wpo-feature-wrap">
                                    <div className="wpo-features-icon">
                                        <i className="fi flaticon-ship"></i>
                                    </div>
                                    <div className="wpo-features-text">
                                        <h3>Webhooks & Status Updates</h3>
                                        <p>Stay up-to-date with automated webhooks and real-time status updates, keeping you informed every step of the way.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-6 col-sm-12">
                                <div className="wpo-feature-wrap">
                                    <div className="wpo-features-icon">
                                        <i className="fi flaticon-truck-1"></i>
                                    </div>
                                    <div className="wpo-features-text">
                                        <h3>Quote & Tracking Embeds</h3>
                                        <p>Embed our quote and tracking tools directly on your platform for a seamless customer experience.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
     
}

export default WpoFeatures;
