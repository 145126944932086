import React, { Component } from 'react';
import { getContactToken, postContactForm } from "../../lib/api";

class ContactForm2 extends Component {
    
    state = {
        name: '',
        email: '',
        phone_number: '',
        address: '',
        message: '',
        error: {},
        token: ''
    }
    
    componentDidMount() {
        getContactToken().then(data => {
            this.setState({ token: data.token });
        }).catch(error => {
            console.log("Error fetching contact token:", error);
        });
    }
    
    changeHandler = (e) => {
        const error = this.state.error;
        error[e.target.name] = '';
        this.setState({
            [e.target.name]: e.target.value,
            error
        });
    }
    
    submitHandler = (e) => {
        e.preventDefault();
        let { name, email, phone_number, subject, token, message, error } = this.state;
        
        error = { ...error,
            name: name ? '' : "Please enter your name",
            email: email ? '' : "Please enter your email",
            phone_number: phone_number ? '' : "Please enter your phone number",
            subject: subject ? '' : "Please enter your subject",
            message: message ? '' : "Please enter your message"
        };
        
        this.setState({ error });
        
        const hasErrors = Object.values(error).some(errorMessage => errorMessage !== '');
        
        if (!hasErrors && token) {
            const contactObject = {
                name, email, phone_number, subject, token, message
            };
            postContactForm(contactObject).then((response) => {
                // Check if the response status is 200 or 201
                if (response.status === 204 || response.status === 200 || response.status === 201) {
                    alert('Message sent!');
                    this.setState({
                        name: '', email: '', phone_number: '', subject: '', message: '', error: {}
                    });
                } else {
                    console.error("Unexpected status code:", response.status);
                }
            }).catch((err) => {
                console.error("Error submitting contact form:", err);
            });
            
        }
    }
    
    render() {
        const { name, subject, email, phone_number, message, error } = this.state;
        
        return (
           <form onSubmit={this.submitHandler}>
               <div className="contact-form form-style row">
                   <div className="col-12 col-lg-6">
                       <input type="text" value={subject} onChange={this.changeHandler} placeholder="Subject*" name="subject"
                              style={{ color: '#333', backgroundColor: '#fff' }} />
                       <p>{error.subject}</p>
                   </div>
                   <div className="col-12 col-lg-6">
                       <input type="text" value={name} onChange={this.changeHandler} placeholder="Your Name*" name="name"
                              style={{ color: '#333', backgroundColor: '#fff' }} />
                       <p>{error.name}</p>
                   </div>
                   <div className="col-12 col-lg-6">
                       <input type="email" placeholder="Your Email" onChange={this.changeHandler} value={email} name="email"
                              style={{ color: '#333', backgroundColor: '#fff' }} />
                       <p>{error.email}</p>
                   </div>
                   <div className="col col-lg-6">
                       <input type="text" placeholder="Phone" onChange={this.changeHandler} value={phone_number} name="phone_number"
                              style={{ color: '#333', backgroundColor: '#fff' }} />
                       <p>{error.phone_number}</p>
                   </div>
                   <div className="col-12 col-sm-12">
                        <textarea className="contact-textarea" value={message} onChange={this.changeHandler} placeholder="Message" name="message"
                                  style={{ color: '#333', backgroundColor: '#fff' }}></textarea>
                       <p>{error.message}</p>
                   </div>
                   <div className="col-12">
                       <button type="submit" className="theme-btn"
                               disabled={!name || !subject || !email || !phone_number || !message}
                               style={{ color: '#fff', backgroundColor: '#333' }}>
                           Send Message
                       </button>
                   </div>
               </div>
           </form>
        );
    }
}

export default ContactForm2;
