import React from  'react';

// components
import Navbar from '../../components/Navbar'
import Breadcumb from '../../components/breadcumb'

import Contactpage from '../../components/Contactpage'
import FooterSection from '../../components/Footer'
//import TransportAir from "../../components/TransportAir";
//import air1 from "../../images/air/5.jpg";
//import air2 from "../../images/air/6.jpg";
import Newsletter from "../../components/Newsletter";


const ContactPage = () => {
    return(
       <div>
           <Navbar/>
           <Breadcumb bdtitle={'Contact Us'} bdsub={'Contact'}/>
           <Contactpage/>
        {/*  <TransportAir simg={air1} simg2={air2}/>*/}
          <Newsletter/>
          <FooterSection/>
       </div>
    )
}

export default ContactPage;
