import React, { Component } from 'react';
import './style.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import team_1 from '../../images/team/1.jpg';
import team_2 from '../../images/team/2.jpg';
import team_3 from '../../images/team/3.jpg';

class TeamSection extends Component {
    render() {
        return (
            <div className="wpo-team-area-2">
                <div className="container">
                    <div className="col-l2">
                        <div className="wpo-section-title text-center">
                            <span>Veterans across multiple industries</span>
                            <h2>Your Friends in Freight</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={team_1} alt=""/>
                                    <div className="d-none social-1st">
                                        <ul>
                                            <li><a target={'_blank'} href="https://instagram.com/parkaftadark"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                                            <li><a target={'_blank'} href="https://twitter.com/parkaftadark"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                            <li><a target={'_blank'} href="https://www.linkedin.com/in/parker-gardner-910748295"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="wpo-team-content">
                                    <h4>Corey Nutgrass</h4>
                                    <span>CEO</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={team_2} alt=""/>
                                    <div className="d-none social-1st">
                                        <ul>
                                            <li><a target={'_blank'} href="https://instagram.com/parkaftadark"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                                            <li><a target={'_blank'} href="https://twitter.com/parkaftadark"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                            <li><a target={'_blank'} href="https://www.linkedin.com/in/parker-gardner-910748295"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="wpo-team-content">
                                    <h4>Parker Gardner</h4>
                                    <span>COO</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <div className="wpo-team-single">
                                <div className="wpo-team-img">
                                    <img src={team_3} alt=""/>
                                    <div className="d-none social-1st">
                                        <ul>
                                            <li><a target={'_blank'} href="https://instagram.com/ustincameron"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                                            <li><a target={'_blank'} href="https://twitter.com/ustincameron"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                            <li><a target={'_blank'} href="https://www.linkedin.com/in/austincameron/"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="wpo-team-content">
                                    <h4>Austin Cameron</h4>
                                    <span>CTO</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TeamSection;
