import React from  'react';

// components
import Navbar from '../../components/Navbar'
import Breadcumb from '../../components/breadcumb'
import TransportAir from '../../components/TransportAir'
import Newsletter from '../../components/Newsletter'
import FooterSection from '../../components/Footer'
//import air1 from '../../images/air/5.jpg'
//import air2 from '../../images/air/6.jpg'
import air1 from '../../images/about/2.jpg'
import air2 from '../../images/pricing/3.png'

const RoadFreight = () => {
    return(
       <div>
           <Navbar/>
           <Breadcumb bdtitle={'Delivery Estimates'} bdsub={'Delivery Estimates'}/>
           <TransportAir simg={air1} simg2={air2}/>
           <Newsletter/>
           <FooterSection/>
       </div>
    )
}

export default RoadFreight;
