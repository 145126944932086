import React from 'react';
import ContactForm2 from '../CommentForm2'

import './style.css'

const Contactpage = () => {

    return(
        <div className="contact-page-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-12">
                        <div className="contact-page-item">
                            <h2>Our Details</h2>
                            <p>Empowering seamless logistics with precision, speed, and innovation, where every shipment counts.</p>
                            <div className="adress">
                                <h3>Address</h3>
                                <span>Louisville, Kentucky, USA</span>
                            </div>
                            <div className="phone">
                                <h3>Phone</h3>
                                <span>502-337-2463</span>
                                <span>502-705-0529</span>
                            </div>
                            <div className="email">
                                <h3>Email</h3>
                                <span>hello@carrierconnectornow.com</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12">
                        <div className="contact-area contact-area-2 contact-area-3">
                            <h2>Quick Contact Form</h2>
                            <ContactForm2/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="contact-map">
                            <iframe title='db' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d200697.70386878014!2d-85.84191782618036!3d38.18895920223839!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88690b1ab35bd511%3A0xd4d3b4282071fd32!2sLouisville%2C%20KY!5e0!3m2!1sen!2sus!4v1708758114507!5m2!1sen!2sus"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
     
}

export default Contactpage;
