import React from 'react';
import {Link} from 'react-router-dom'
import './style.css'

const RiskManagementPlan = (props) => {

    return(
        <div className="Freight-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-12">
                        <div className="row">
                            <div className="col-lg-12 col-md-6">
                                <div className="contact-us-area">
                                    <h3>Contact Us</h3>
                                    <div className="contact-s">
                                        <span><i className="fa fa-phone" aria-hidden="true"></i>Call Us</span>
                                        <p>Tel: 502-337-2464</p>
                                        <p>Tel: 502-705-0529</p>
                                    </div>
                                    <div className="contact-s">
                                        <span><i className="fa fa-envelope-o" aria-hidden="true"></i>Email Us</span>
                                        <p>hello@carrierconnectnow.com</p>
                                    </div>
                                    <div className="contact-s">
                                        <span><i className="fa fa-map-marker" aria-hidden="true"></i>Our Location</span>
                                        <p>Louisville, KY, USA</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-6">
                                <div className="catagory-item">
                                    <div className="widget-title">
                                        <h3 className="text-left">Our Services</h3>
                                    </div>
                                    <div className="category-section">
                                        <ul>
                                            <li><Link to="/">Auto Transport</Link></li>
                                            <li><Link to="/">Marine Transport</Link></li>
                                            <li><Link to="/">Hazmat Transport</Link></li>
                                            <li><Link to="/">Material Freight</Link></li>
                                            <li><Link to="/">Cargo Freight</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9"><div className="Freight-item">
                        <h3>Comprehensive Insurance & Risk Management Plan</h3>
                        <p>At Carrier Connect, we ensure the safety and security of goods from pickup to delivery through our dedicated Insurance and Risk Management Plan. Our plan is designed to mitigate risks and provide unparalleled logistics services.</p>
                        
                        <h4>Risk Assessment & Insurance Coverage</h4>
                        <p>Our quarterly risk analyses and annual policy reviews with top-rated insurers like InsureLogistics Inc. and SecureCargo Co. ensure your freight is always protected. Specialized coverage for diverse goods, including perishables and hazardous materials, is our standard.</p>
                        
                        <h4>Safety Measures & Claims Management</h4>
                        <p>From tamper-evident seals and GPS tracking to digital claims processing, we implement state-of-the-art security and efficiency protocols. Our real-world applications like the SecurePack™ system have significantly reduced package tampering incidents.</p>
                        
                        <h4>Compliance, Certifications, and Client Testimonials</h4>
                        <p>Adhering to TIA and NITL best practices, we're not just about meeting standards but exceeding them. Our clients, like BigRetail Co., have experienced our efficient claim handling firsthand, testifying to our commitment to excellence.</p>
                        
                        <div className="row">
                            <div className="col-md-12">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th scope="col">Feature</th>
                                        <th scope="col">Details</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>Insurance Types</td>
                                        <td>Cargo, Liability, Comprehensive Coverage, and more</td>
                                    </tr>
                                    <tr>
                                        <td>Security Enhancements</td>
                                        <td>GPS tracking, SecurePack™ system, Bi-annual safety training</td>
                                    </tr>
                                    <tr>
                                        <td>Claims Processing</td>
                                        <td>Digital portal for efficient filing, average resolution time of 14 days</td>
                                    </tr>
                                    <tr>
                                        <td>Compliance & Certifications</td>
                                        <td>TIA, NITL, and regular audits for cybersecurity and financial stability</td>
                                    </tr>
                                    <tr>
                                        <td>Client Testimonials</td>
                                        <td>Exemplary feedback on swift claim handling and comprehensive coverage</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        
                        <div class="Freight-img">
                            <img src={props.simg2} alt="Carrier Connect Insurance and Risk Management"/>
                        </div>
                        <div class="f-s">
                            <span>Ensure your freight's safety with Carrier Connect's comprehensive plan.</span>
                            <span>Our proactive approach to risk management protects your interests.</span>
                            <span>Experience peace of mind with our strategic insurance partnerships.</span>
                            <span>Join the ranks of satisfied clients who trust Carrier Connect.</span>
                            <span>Discover the difference with Carrier Connect's risk management today.</span>
                        </div>
                    </div>
                    
                    </div>
                
                
                </div>
            </div>
        </div>
     )
     
}

export default RiskManagementPlan;
