import React from 'react';
import {Link} from 'react-router-dom'
import './style.css'

const TransportAir = (props) => {

    return(
        <div className="Freight-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-12">
                        <div className="row">
                            <div className="col-lg-12 col-md-6">
                                <div className="contact-us-area">
                                    <h3>Contact Us</h3>
                                    <div className="contact-s">
                                        <span><i className="fa fa-phone" aria-hidden="true"></i>Call Us</span>
                                        <p>Tel: 502-337-2464</p>
                                        <p>Tel: 502-705-0529</p>
                                    </div>
                                    <div className="contact-s">
                                        <span><i className="fa fa-envelope-o" aria-hidden="true"></i>Email Us</span>
                                        <p>hello@carrierconnectnow.com</p>
                                    </div>
                                    <div className="contact-s">
                                        <span><i className="fa fa-map-marker" aria-hidden="true"></i>Our Location</span>
                                        <p>Louisville, KY, USA</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-6">
                                <div className="catagory-item">
                                    <div className="widget-title">
                                        <h3 className="text-left">Our Services</h3>
                                    </div>
                                    <div className="category-section">
                                        <ul>
                                            <li><Link to="/">Auto Transport</Link></li>
                                            <li><Link to="/">Marine Transport</Link></li>
                                            <li><Link to="/">Hazmat Transport</Link></li>
                                            <li><Link to="/">Material Freight</Link></li>
                                            <li><Link to="/">Cargo Freight</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="Freight-item">
                            <h3>Delivery Time Estimates</h3>
                            <p>Wondering how long it will take to ship your vehicle? At Carrier Connect, we pride ourselves on delivering top-notch service worldwide. Our commitment to excellence ensures that your shipment arrives safely and on time.</p>
                            <p>Our experienced team is dedicated to providing you with the best service possible. We understand that timely delivery is essential, and we go above and beyond to meet your expectations.</p>

                            <img src={props.simg} alt="" style={{height:200, margin:'auto', display:'none'}}/>
                            <div className="row">
                                <div className="col-md-6">
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th scope="col">Distance (miles)</th>
                                            <th scope="col">Estimated Days</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>1 – 100</td>
                                            <td>1 day</td>
                                        </tr>
                                        <tr>
                                            <td>101 – 350</td>
                                            <td>1-2 days</td>
                                        </tr>
                                        <tr>
                                            <td>351 – 500</td>
                                            <td>2-3 days</td>
                                        </tr>
                                        <tr>
                                            <td>501 – 800</td>
                                            <td>2-4 days</td>
                                        </tr>
                                        <tr>
                                            <td>801 – 1,200</td>
                                            <td>3-5 days</td>
                                        </tr>
                                        <tr>
                                            <td>1,201 – 1,500</td>
                                            <td>4-7 days</td>
                                        </tr>
                                        <tr>
                                            <td>1,501 – 1,800</td>
                                            <td>5-8 days</td>
                                        </tr>
                                        <tr>
                                            <td>1,801 – 2,200</td>
                                            <td>6-9 days</td>
                                        </tr>
                                        <tr>
                                            <td>2,201 – 2,800</td>
                                            <td>7-10 days</td>
                                        </tr>
                                        <tr>
                                            <td>2,801 or more</td>
                                            <td>8-12 days</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-6">
                                    <div className="Freight-img">
                                        <img src={props.simg2} alt=""/>
                                    </div>
                                    <div className="f-s">
                                        <span>Count on us for accurate delivery time estimates.</span>
                                        <span>Our goal is to exceed your expectations with every shipment.</span>
                                        <span>Trust Carrier Connect for reliable and efficient service.</span>
                                        <span>We prioritize safety and efficiency in every delivery.</span>
                                        <span>Experience the difference with Carrier Connect.</span>
                                        <span>Rest assured, your shipment is in good hands with us.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                
                
                </div>
            </div>
        </div>
     )
     
}

export default TransportAir;
