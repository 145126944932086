import React from 'react';

import './style.css'

const PricingSection = (props) => {

    return(
        <div className="wpo-pricing-area">
            <div className="container">
                <div className="wpo-section-title text-center">
                   <span>Transparent, No-Surprises Pricing</span>
                    <h2>Choose Your Perfect Plan</h2>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="row">
                            <div className="col-lg-4 col-l2 col-md-6 col-12">
                                <div className="wpo-price-item">
                                    <div className="wpo-pricing-header">
                                        <h3>Starter</h3>
                                        <span>Perfect for small businesses</span>
                                    </div>
                                    <div className="wpo-pricing-table">
                                        <div className="wpo-price-sub-header">
                                            <h4>$200</h4>
                                        </div>
                                        <ul style={{ paddingLeft:50, textAlign: 'left' }}>
                                            <li><i className="fa fa-check-circle mr-1"></i>Units Shipped: 100</li>
                                            <li><i className="fa fa-check-circle mr-1"></i>Direct API Access</li>
                                            <li><i className="fa fa-circle-o mr-1"></i>Webhooks*</li>
                                            <li><i className="fa fa-circle-o mr-1"></i>Delivery Email / SMS Alerts*</li>
                                            <li><i className="fa fa-circle-o mr-1"></i>No-Code Quote & Tracking Embeds*</li>
                                            <li><i className="fa fa-circle-o mr-1"></i>White-labeled Experience*</li>
                                            <li><i className="fa fa-info-circle mr-1"></i>Overage: $2.50</li>
                                        </ul>
                                        <div className="wpo-pricing-footer">
                                            <div className="btns">
                                                <div className="btn-style btn-style-4"><a href="https://buy.stripe.com/test_4gwg1Xb5C7XhfM45km">Start Now</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-l2 col-md-6 col-12">
                                <div className="wpo-price-item">
                                    <div className="wpo-pricing-header">
                                        <h3>Growth</h3>
                                        <span>Perfect for growing businesses</span>
                                    </div>
                                    <div className="wpo-pricing-table">
                                        <div className="wpo-price-sub-header">
                                            <h4>$400</h4>
                                        </div>
                                        <ul style={{ paddingLeft:50, textAlign: 'left' }}>
                                            <li><i className="fa fa-check-circle mr-1"></i>Units Shipped: 250</li>
                                            <li><i className="fa fa-check-circle mr-1"></i>Direct API Access</li>
                                            <li><i className="fa fa-plus-circle mr-1"></i>Webhooks*</li>
                                            <li><i className="fa fa-plus-circle mr-1"></i>Delivery Email / SMS Alerts*</li>
                                            <li><i className="fa fa-plus-circle mr-1"></i>No-Code Quote & Tracking Embeds*</li>
                                            <li><i className="fa fa-circle-o mr-1"></i>White-labeled Experience*</li>
                                            <li><i className="fa fa-info-circle mr-1"></i>Overage: $2.00</li>
                                        </ul>
                                        <div className="wpo-pricing-footer">
                                            <div className="btns">
                                                <div className="btn-style btn-style-4"><a href="https://buy.stripe.com/test_28o9DzgpW6Td8jC9AB">Start Now</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-l2 col-md-6 col-12">
                                <div className="wpo-price-item">
                                    <div className="wpo-pricing-header">
                                        <h3>Pro</h3>
                                        <span>Perfect for larger logistic operations</span>
                                    </div>
                                    <div className="wpo-pricing-table">
                                        <div className="wpo-price-sub-header">
                                            <h4>$500</h4>
                                        </div>
                                        <ul style={{ paddingLeft:50, textAlign: 'left' }}>
                                            <li><i className="fa fa-check-circle mr-1"></i>Units Shipped: 500</li>
                                            <li><i className="fa fa-check-circle mr-1"></i>Direct API Access</li>
                                            <li><i className="fa fa-plus-circle mr-1"></i>Webhooks*</li>
                                            <li><i className="fa fa-plus-circle mr-1"></i>Delivery Email / SMS Alerts*</li>
                                            <li><i className="fa fa-plus-circle mr-1"></i>No-Code Quote & Tracking Embeds*</li>
                                            <li><i className="fa fa-plus-circle mr-1"></i>White-labeled Experience*</li>
                                            <li><i className="fa fa-info-circle mr-1"></i>Overage: $1.50</li>
                                        </ul>
                                        <div className="wpo-pricing-footer">
                                            <div className="btns">
                                                <div className="btn-style btn-style-4"><a href="https://buy.stripe.com/test_bIY5nj4Hea5p0Ra4gj">Start Now</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
     
}

export default PricingSection;
