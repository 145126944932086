import React, { Component } from 'react';
import Slider from "react-slick";
import './style.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import testimonialImg_1 from '../../images/testimonial/2.jpg';

class Testimonial extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 1200,
            slidesToShow: 1,
            arrows: false,
            margin:50,
            autoplay:true,
            slidesToScroll: 1,
            centerPadding: 30,
            focusOnSelect: false,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 2,
                    initialSlide: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
            ]
        };
        return (
            <div className="wpo-testimonial-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <div className="wpo-testimonial-active owl-carousel">
                                <Slider {...settings}>
                                    <div className="wpo-testimonial-wrap">
                                        <div className="wpo-testimonial-img">
                                            <img src={testimonialImg_1} alt=""/>
                                        </div>
                                        <div className="wpo-testimonial-item">
                                            <div className="wpo-testimonial-content">
                                                <p>Carrier Connect is truly dedicated to the carriers in the industry. They go over and beyond daily to ensure that you have the tools and resources you need to complete the job at task. Great guys to work with. Definitely a 10!</p>
                                                <h4>BDB Truckin LLC</h4>
                                                <span>BJ McDowell</span>
                                            </div>
                                        </div>
                                        <div className="test-c d-none d-lg-block"></div>
                                        <div className="test-b d-none d-lg-block"></div>
                                    </div>
                                    <div className="wpo-testimonial-wrap">
                                        <div className="wpo-testimonial-img">
                                            <img src={testimonialImg_1} alt=""/>
                                        </div>
                                        <div className="wpo-testimonial-item">
                                            <div className="wpo-testimonial-content">
                                                <p>On-Time Payments, Good Communication & Accurate Information! We are happy working together.</p>
                                                <h4>Alcon Trans Corp</h4>
                                                <span></span>
                                            </div>
                                        </div>
                                        <div className="test-c d-none d-lg-block"></div>
                                        <div className="test-b d-none d-lg-block"></div>
                                    </div>
                                    <div className="wpo-testimonial-wrap">
                                        <div className="wpo-testimonial-img">
                                            <img src={testimonialImg_1} alt=""/>
                                        </div>
                                        <div className="wpo-testimonial-item">
                                            <div className="wpo-testimonial-content">
                                                <p>Accurate Information, Good Communication & On-Time Payments. Thank you!</p>
                                                <h4>GeoSun Corp</h4>
                                                <span></span>
                                            </div>
                                        </div>
                                        <div className="test-c d-none d-lg-block"></div>
                                        <div className="test-b d-none d-lg-block"></div>
                                    </div>
                                   <div className="wpo-testimonial-wrap">
                                      <div className="wpo-testimonial-img">
                                         <img src={testimonialImg_1} alt=""/>
                                      </div>
                                      <div className="wpo-testimonial-item">
                                         <div className="wpo-testimonial-content">
                                            <p>Great Communication - Great company to work with!</p>
                                            <h4>MHDH Logistics Inc</h4>
                                            <span></span>
                                         </div>
                                      </div>
                                      <div className="test-c d-none d-lg-block"></div>
                                      <div className="test-b d-none d-lg-block"></div>
                                   </div>
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Testimonial;
