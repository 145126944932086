import React from 'react';
import abimg2 from '../../images/about/2.png'

import './style.css'

const AboutSection2 = () => {

    return(
        <div className="wpo-about-style-2">
        <div className="container">
            <div className="row">
                <div className="col-lg-6  offset-lg-6 about-wrap">
                    <div className="wpo-about-content">
                        <div className="wpo-about-icon">
                            <i className="fi flaticon-travel"></i>
                        </div>
                        <h2>Who We Are?</h2>
                        <p>Driven by a blend of innovation and a deep-rooted commitment to our clients, we navigate the complexities of global logistics with ease. Our expertise spans across a spectrum of services, from domestic road freight to expansive marine transport, each tailored to meet the evolving demands of the modern market.</p>

                        <span>Leading with Technology for Seamless Logistics</span>
                        <span>Customized Solutions for Modern Transportation Needs</span>
                        <span>Commitment to Precision, Care, and Efficiency</span>
                    </div>
                    <div className="signature-section">
                        <div className="si-text">
                            <p>Corey Nutgrass</p>
                            <span>Chairman & Chief Executive Officer</span>
                        </div>
                        <img src={abimg2} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </div>
     )
     
}

export default AboutSection2;
