import axios from 'axios';
import { API_HOST } from '../constants/constants';

export const postContactForm = async (options) => {
  const apiURL = API_HOST + '/contact_form';
  
  try {
    const response = await axios.post(apiURL, {...options}, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    // Return both data and status
    return {
      data: response.data,
      status: response.status
    };
  } catch (error) {
    console.error("Network error:", error);
    // Consider what to return or throw in case of an error
    throw error; // This will ensure the calling function can catch and handle errors.
  }
};
