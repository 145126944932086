import React from 'react';
import {Link} from 'react-router-dom'

import './style.css'


const CounterSection = (props) => {

    return(
        <div className={`wpo-counter-area ${props.subclass}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 col-md-6 col-sm-12">
                        <div className="wpo-counter-content">
                            <h2>Our Achievements<br/>& Your Opportunities</h2>
                            <p>Emerging in the domestic logistics arena with UCR registration and FMCSA bonding, we're setting new standards for reliability and excellence. Our commitment to your logistics needs aims to foster trust and satisfaction among our clients through accredited and secure services. Discover how we can enhance your operations.</p>
                            <div className="btns">
                                <div className="btn-style btn-style-3"><Link to="/">Ready to begin?</Link></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-6 col-sm-12">
                        <div className="wpo-counter-grids">
                            <div className="grid">
                                <div>
                                    <h2><span>475</span></h2>
                                </div>
                                <p>VINs Delivered</p>
                            </div>
                            <div className="grid">
                                <div>
                                    <h2><span>17.5k</span></h2>
                                </div>
                                <p>Tons of Material Goods</p>
                            </div>
                            <div className="grid">
                                <div>
                                    <h2><span>2k+</span></h2>
                                </div>
                                <p>Vetted Carriers Empowered</p>
                            </div>
                            <div className="grid">
                                <div>
                                    <h2><span>250+</span></h2>
                                </div>
                                <p>Satisfied Clients</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
     
}

export default CounterSection;
