import React from  'react';

// components
import Navbar from '../../components/Navbar'
import Breadcumb from '../../components/breadcumb'
import PricingSection from '../../components/Pricing'
import Newsletter from '../../components/Newsletter'
import FooterSection from '../../components/Footer'
//import Features2 from "../../components/features2";
import AboutSection2 from "../../components/about2";
//import Mission from "../../components/Mission";
//import TeamSection from "../../components/Team";


const PricingPage = () => {
    return(
       <div className="price-sec">
           <Navbar/>
           <Breadcumb bdtitle={'Our Pricing'} bdsub={'Our Pricing'}/>
           <PricingSection/>
          
{/*
          <Features2/>
*/}
          <AboutSection2/>
          
     {/*     <Mission/>
          <TeamSection/>*/}
          
          <Newsletter nwclass={'wpo-newsletter-section'}/>
          <FooterSection/>
       </div>
    )
}

export default PricingPage;
