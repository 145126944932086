import React from 'react';
import {Link} from 'react-router-dom'
import logo from '../../images/logo.svg'
import in1 from '../../images/instragram/1.jpg'
import in2 from '../../images/instragram/2.jpg'
import in3 from '../../images/instragram/3.jpg'
import in4 from '../../images/instragram/4.jpg'
import in5 from '../../images/instragram/5.jpg'
import in6 from '../../images/instragram/6.jpg'
import './style.css'

const FooterSection = () => {

    return(
        <div className="wpo-footer-area">
            <div className="wpo-footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6 footer-t">
                            <div className="wpo-footer-logo">
                                <img src={logo} alt=""/>
                            </div>
                            <p>Empowering seamless logistics with precision, speed, and innovation, where every shipment counts.</p>
                            <p>FMCSA Authorized:<br/>DOT #4121110 | MC #1576167</p>
                            <div className="social">
                                <ul className="d-flex">
                                    <li><a target={'_blank'} href="https://www.instagram.com/carrier_connect"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                                    <li><a target={'_blank'} href="https://www.twitter.com/carrier_connect"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    <li><a target={'_blank'} href="https://www.linkedin.com/company/carrierconnectnow/"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 footer-t">
                            <div className="footer-link">
                                <h3>Quick Link</h3>
                                <ul>
                                    <li><Link to="/contact">Schedule a Call</Link></li>
                                    <li><Link to="/prices">Pricing</Link></li>
                                    <li><Link to="/documentation">API Documentation</Link></li>
                                    <li><Link to="/risk_management">Risk Management</Link></li>
                                    <li><Link to="/contact">Contact Us</Link></li>
                                   {/* <li><Link to="/">Blog</Link></li>*/}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 footer-b">
                            <div className="Recent-News-area">
                                <h3>Recent News</h3>
                                <div className="resent-sub">
                                    <p>Navigating the Future: Innovations in Logistics for 2024</p>
                                    <span><i className="fa fa-clock-o" aria-hidden="true"></i> January 10, 2024</span>
                                </div>
                                <p>Green Transportation: How Eco-Friendly Practices are Transforming Deliveries</p>
                                <span><i className="fa fa-clock-o" aria-hidden="true"></i> February 24, 2024</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-12">
                            <div className="footer-widget instagram">
                                <h3>Instagram</h3>
                                <ul className="d-flex">
                                    <li><a target={'_blank'} href="https://www.instagram.com/carrier_connect"><img src={in1} alt=""/></a></li>
                                    <li><a target={'_blank'} href="https://www.instagram.com/carrier_connect"><img src={in2} alt=""/></a></li>
                                    <li><a target={'_blank'} href="https://www.instagram.com/carrier_connect"><img src={in3} alt=""/></a></li>
                                    <li><a target={'_blank'} href="https://www.instagram.com/carrier_connect"><img src={in4} alt=""/></a></li>
                                    <li><a target={'_blank'} href="https://www.instagram.com/carrier_connect"><img src={in5} alt=""/></a></li>
                                    <li><a target={'_blank'} href="https://www.instagram.com/carrier_connect"><img src={in6} alt=""/></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wpo-footer-bottom">
                <div className="container">
                    <div className="wpo-footer-bottom-content">
                        <div className="row">
                            <div className="col-12">
                                <span>© Copyrights 2024. All Rights Reserved.</span>
                                <p>Your trusted partner in comprehensive logistics solutions, offering efficient vehicle transport, state-of-the-art tracking technology, and unwavering commitment to customer satisfaction. Operating across the United States, we connect businesses and customers with seamless, secure, and environmentally responsible logistics services. Explore our site to learn more about our innovative shipping solutions, real-time delivery updates, and competitive pricing plans designed to meet diverse needs. Join us in driving the future of logistics forward.</p>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     )
     
}

export default FooterSection;
