import React from  'react';

// components
import Navbar from '../../components/Navbar'
import Breadcumb from '../../components/breadcumb'
import Newsletter from '../../components/Newsletter'
import FooterSection from '../../components/Footer'
import air1 from '../../images/about/2.jpg'
import air2 from '../../images/pricing/3.png'
import RiskManagementPlan from "../../components/RiskManagementPlan";

const RiskManagementPlanPage = () => {
    return(
       <div>
           <Navbar/>
           <Breadcumb bdtitle={'Comprehensive Insurance & Risk Management Plan'} bdsub={'Risk Management'}/>
           <RiskManagementPlan simg={air1} simg2={air2}/>
           <Newsletter/>
           <FooterSection/>
       </div>
    )
}

export default RiskManagementPlanPage;
