import axios from "axios";
import { API_HOST } from "../constants/constants";

export const getContactToken = async () => {

    const apiURL = `${API_HOST}/contact_form`;

    try {
        const response = await axios.get(apiURL, {
            params: {
            },
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response.data
        //return { valid: response.data.success, token: response.data.token, error: null }; // Assuming the API returns an object with a boolean `isValid` field
    } catch (error) {
        console.error('Error getting contact token:', error);
        return { valid: false, error: error.response?.data.message || 'An error occurred during contact token' };
    }
};
